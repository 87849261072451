import mutations from './mutations';
import {notification} from "ant-design-vue";
import {DataService} from "../../../config/dataService/dataService";

const addNotificationError = (err) => {
    notification.error({
        message: err,
    });
};

const state = () => ({
    order: {
        type: 'SOLAR',
        relation: {type: 'CONSUMER'},
        relationID: null,
        signatureType: 'ESIGNATURE',
        signatureData: null,
        flowQuestions: [],
        panelCount: null,
        surfaces: [],
        key: null,
        package: null,
        satelliteImageFileURL: null,
        selectedRoofLayers: [],
        maxDiscountPercentage: 0,
        allowDiscount: false,
        discount: null,
        discountType: 'ORDER',
        solarAddonProducts: [],
        solarSatelliteImage: null,
        solarEnergyUsage: null,
        solarData: {
            meterCupboard: 1,
        },
    },
    subProducts: {
        data: [],
        key: null,
    },
    subProduct: [],
    solarPackages: {
        data: [],
        key: null,
    },
    createConfirmOrder: null,
    roofData: [],
    solarQuestions: [],
    surfaceOrientationOfEdge: [],
    solarData: {},
    loadingCreateOrder: false,
    errorCreateOrder: false,
    errorFetchSolarQuestions: false,
    loadingFetchSolarQuestions: false,
    errorPatchSolarQuestions: false,
    loadingPatchSolarQuestions: false,
    errorFetchSolarProducts: false,
    loadingFetchSolarProducts: true,
    errorPreFillRelationOnSolarOrder: false,
    loadingPreFillRelationOnSolarOrder: false,
    errorFetchRoofData: false,
    loadingFetchRoofData: false,
    errorFetchSolarPackagesDataErr: false,
    loadingFetchSolarPackagesData: false,
    errorSolarSubProducts: false,
    loadingSolarSubProducts: false,
    errorSolarSubProduct: false,
    loadingSolarSubProduct: false,
    errorPatchSolarSubProduct: false,
    loadingPatchSolarSubProduct: false,
    errorFetchSolarPackageDataErr: false,
    loadingFetchSolarPackageData: false,
    loadingFetchSolarPackageAddons: false,
    errorFetchSolarPackageAddonsErr: false,
    loadingCreateConfirmOrder: false,
    errorCreateConfirmOrder: false,
});

const actions = {
    async setOrderFlowSolar({commit}, flow) {
        await commit('setOrderFlowSolar', flow);
    },
    async setSignatureSolarData({commit}, flow) {
        await commit('setSignatureSolarData', flow);
    },
    async addRelationToSolarOrder({commit}, data) {
        await commit('addRelationToSolarOrder', data.value);
        await data.stepRoute();
    },
    async preFillRelationOnSolarOrder({commit}, data) {
        try {
            await commit('preFillRelationOnSolarOrderBegin');
            await commit('addRelationToSolarOrder', data.value);
            await commit('preFillRelationData', data.value)
            await commit('preFillRelationOnSolarOrderSuccess');
            await data.stepRoute();
        } catch (err) {
            await commit('preFillRelationOnSolarOrderErr', err);
            addNotificationError(err?.response?.data?.message);
        }
    },
    async createSolarOrder({commit, dispatch, state}, data) {
        try {
            const order = state.order;
            if (order.discount && order.discount !== '') {
                order.discount = parseFloat(order.discount);
            }
            await commit('solarOrderCreateBegin');
            await DataService.post(`/orders`, state.order);
            await commit('solarOrderCreateSuccess');
            await dispatch('setCurrentPageOrder', 1);
            await data.successRoute();
            // await commit('resetSolarOrder');
        } catch (err) {
            await commit('solarOrderCreateErr', err);
            addNotificationError(err?.response?.data?.message);
        }
    },
    async resetSolarOrder({commit}) {
        await commit('resetSolarRoof');
        await commit('resetSolarOrderSurfaces');
        await commit('resetSelectedRoofLayers');
        await commit('resetSolarRelation');
        await commit('resetSolarOrder');
    },
    async fetchRoofData({commit}, data) {
        try {
            await commit('fetchRoofDataBegin');
            const response = await DataService.get(`/offerflow/solar/roofdata`, data);
            await commit('fetchRoofDataSuccess', response.data);
        } catch (err) {
            await commit('fetchRoofDataErr', err);
            addNotificationError(err?.response?.data?.message);
        }
    },
    async fetchSolarPackages({commit}, data) {
        try {
            await commit('fetchSolarPackagesDataBegin');
            const response = await DataService.post(`/offerflow/solar/packages`, data);
            await commit('fetchSolarPackagesDataSuccess', response.data);
        } catch (err) {
            await commit('fetchSolarPackagesDataErr', err);
            addNotificationError(err?.response?.data?.message);
        }
    },
    async fetchSolarPackage({commit}, data) {
        try {
            await commit('fetchSolarPackageDataBegin');
            const response = await DataService.post(`/offerflow/solar/package/${data.productId}`, data);
            await commit('fetchSolarPackageDataSuccess', response.data);
        } catch (err) {
            await commit('fetchSolarPackageDataErr', err);
            addNotificationError(err?.response?.data?.message);
        }
    },
    async fetchSolarPackageAddon({commit}, productId) {
        try {
            await commit('fetchSolarPackageAddonsBegin');
            const response = await DataService.get(`/offerflow/solar/package/${productId}/addons`);
            await commit('fetchSolarPackageAddonsSuccess', response.data);
        } catch (err) {
            await commit('fetchSolarPackageAddonsErr', err);
            addNotificationError(err?.response?.data?.message);
        }
    },
    async fetchOrderSolarData({commit}, id) {
        try {
            await commit('fetchOrderSolarDataBegin');
            const response = await DataService.get(`/orders/${id}/solardata`);
            await commit('fetchOrderSolarDataSuccess', response.data);
        } catch (err) {
            await commit('fetchOrderSolarDataErr', err);
            addNotificationError(err?.response?.data?.message);
        }
    },
    async setProductOnSolarOrder({commit}, data) {
        await commit('setProductOnSolarOrder', data.item);
        await data.stepRoute();
    },
    async fetchCustomSurfacePotential({commit}, data) {
        try {
            await commit('fetchCustomSurfacePotentialBegin');
            const response = await DataService.post('/offerflow/solar/customsurfacepotential', data);
            await commit('fetchCustomSurfacePotentialSuccess', response.data);
        } catch (err) {
            await commit('fetchCustomSurfacePotentialErr', err);
            addNotificationError(err?.response?.data?.message);
        }
    },
    async createConfirmOrder({commit}, data) {
        try {
            await commit('createConfirmOrderBegin');
            const response = await DataService.post(`/orders/${data.id}/solar/createconfirmorder`);
            await commit('createConfirmOrderSuccess', response.data);
            await data.navigate(response.data.newID);
        } catch (err) {
            await commit('createConfirmOrderErr', err);
            addNotificationError(err?.response?.data?.message);
        }
    },
    setPanelCountOnSolarOrder({commit}, data) {
        commit('setPanelCountOnSolarOrder', data);
    },
    setElectricityUsageOnSolarOrder({commit}, data) {
        commit('setElectricityUsageOnSolarOrder', data);
    },
    addSurfaceToOrder({commit}, data) {
        commit('addSurfaceToOrder', data);
    },
    updateSurfaceOnOrder({commit}, data) {
        commit('updateSurfaceOnOrder', data);
    },
    updateSurfaceOrientationOnOrder({commit}, data) {
        commit('updateSurfaceOrientationOnOrder', data);
    },
    removeSurfaceFromOrder({commit}, data) {
        commit('removeSurfaceFromOrder', data);
    },
    removeSurfaceFromOrderByKey({commit}, data) {
        commit('removeSurfaceFromOrderByKey', data);
    },
    setRoofSurfaceShadowLossOnOrder({commit}, data) {
        commit('setRoofSurfaceShadowLossOnOrder', data);
    },
    resetSolarSubProduct({commit}) {
        commit('resetSolarSubProduct');
    },
    resetSolarProduct({commit}) {
        commit('resetSolarProduct');
    },
    resetSolarOrderSurfaces({commit}) {
        commit('resetSolarOrderSurfaces');
    },
    resetSolarRoof({commit}) {
        commit('resetSolarRoof');
    },
    resetSelectedRoofLayers({commit}) {
        commit('resetSelectedRoofLayers');
    },
    addSelectedRoofLayers({commit}, data) {
        commit('addSelectedRoofLayers', data);
    },
    removeSelectedRoofLayers({commit}, index) {
        commit('removeSelectedRoofLayers', index);
    },
    resetSolarUsageData({commit}) {
        commit('resetSolarUsageData');
    },
    resetSolarRelation({commit}) {
        commit('resetSolarRelation');
    },
    addFeatureToRoofData({commit}, data) {
        commit('addFeatureToRoofData', data);
    },
    setSolarAddonQuantity({commit}, data) {
        commit('setSolarAddonQuantity', data);
    },
    setSolarSatelliteImage({commit}, data) {
        commit('setSolarSatelliteImage', data);
    },
    showErrNotification(data) {
        addNotificationError(data);
    }
};

export default {
    namespaced: false,
    state,
    actions,
    mutations,
};

